import React, { useState } from 'react';
import { FAQAccordionWrapper, FAQAccordionItem, FAQAccordionHeading, FAQAccordionSubheading, FAQAccordionContent, ItemWrapper } from './styles/FAQAccordion.styled';
import { switchbackResolveRichText } from "../../utils/resolve-rich-text/switchbackResolveRichText"
import onKeyDown from "../../utils/onKeyDown"

const FAQAccordion = ({ component, blog }) => {
  const [faqOpened, setFaqOpened] = useState({});

  const handleFaqClicked = (index) => {
    setFaqOpened({
      ...faqOpened,
      [index]: !faqOpened[index],
    });
  }

  return (
    <FAQAccordionWrapper
      data-comp={FAQAccordion.displayName}
      blog={blog}
      noTopPadding={component?.noTopPadding}
      noBottomPadding={component?.noBottomPadding}
    >
      {component?.heading && <FAQAccordionHeading>{component?.heading}</FAQAccordionHeading>}
      {component?.subhead && <FAQAccordionSubheading>{component?.subhead}</FAQAccordionSubheading>}
      {component?.faQs?.length > 0 && (
        <FAQAccordionContent>
          {component.faQs.map((item, index) => (
            <FAQAccordionItem className={`${faqOpened[index] ? 'opened' : ''}`} key={index}>
              <ItemWrapper className={`${faqOpened[index] ? 'opened' : ''}`}>
                <div
                  className="item-heading"
                  role="button"
                  onClick={() => handleFaqClicked(index)}
                  onKeyDown={(e) => onKeyDown(e, () => handleFaqClicked(index))}
                  tabIndex={0}
                  aria-label={faqOpened[index] ? `Collapse ${item?.question}` : `Expand ${item?.question}`}
                  aria-expanded={faqOpened[index]}
                >
                  {item?.question}
                </div>
                <div className="item-content">
                  {switchbackResolveRichText(item?.answer)}
                </div>
              </ItemWrapper>
            </FAQAccordionItem>
          ))}
        </FAQAccordionContent>
      )}
    </FAQAccordionWrapper>
  );
};

FAQAccordion.displayName = 'FAQAccordion';
export default FAQAccordion
