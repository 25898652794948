import styled, { css } from "styled-components"

export const FAQAccordionWrapper = styled.div`
  ${(props) => props?.blog && props?.blog === true ? css`
    padding: 64px 0 0;
    @media (max-width: 992px) {
      padding: 56px 0 0;
    }
    @media (max-width: 768px) {
      padding: 40px 0 0;
    }
  ` : css`
    padding: 96px 30px;
    @media (max-width: 992px) {
      padding: 56px 30px;
    }
    @media (max-width: 768px) {
      padding: 40px 30px;
    }
  `}
  max-width: 1230px;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  @media (max-width: 992px) {
    max-width: 830px;
  }
  @media (max-width: 768px) {
    max-width: 566px;
  }
  ${({ noTopPadding, noBottomPadding }) => css`
    padding-top: ${noTopPadding === true && 0};
    padding-bottom: ${noBottomPadding === true && 0};
    @media (max-width: 992px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
    @media (max-width: 768px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
  `}
`

export const FAQAccordionHeading = styled.h4`
  font-size: 38px;
  line-height: 48px;
  color: #1B1B1B;
  @media (max-width: 992px) {
    font-size: 32px;
    line-height: 42px;
  }
  @media (max-width: 576px) {
    font-size: 28px;
    line-height: 34px;
  }
`

export const FAQAccordionSubheading = styled.p`
  margin-top: 12px;
  font-size: 22px;
  line-height: 30px;
  color: #475467;
  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const FAQAccordionContent = styled.div`
  margin-top: 32px;
`

export const FAQAccordionItem = styled.div`
  white-space: normal;
  margin: 20px 0px;
  max-width: 100%;
  border-radius: 40px;
  border: 1px solid #D0D5DD;
  transition: border-radius 0.2s ease-in-out, background-color 0.2s ease-in-out;
  &.opened {
    border-radius: 20px;
    background-color: #F2F4F7;
    .faq-accordion--item-content {
      margin-top: 4px;
    }
  }
`;

export const ItemWrapper = styled.div`
  .item-heading {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    padding: 20px 45px 20px 20px;
    cursor: pointer;
    position: relative;
    color: #1B1B1B;
    &:after {
      content: ' ';
      position: absolute;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath d='M6.34317 7.75732L4.92896 9.17154L12 16.2426L19.0711 9.17157L17.6569 7.75735L12 13.4142L6.34317 7.75732Z' fill='currentColor' /%3E%3C/svg%3E");
      right: 15px;
      top: 22px;
      transition: transform 0.2s ease-in-out;
    }
  }
  .item-content {
    white-space: normal;
    overflow: hidden;
    padding: 0 20px;
    max-height: 0;
    transition: max-height 0.2s ease-in-out, padding-bottom 0.2s ease-in-out;
    color: #475467;
    p {
      line-height: 150%;
    }
  }
  &.opened {
    .item-heading {
      &:after {
        transform: rotate(180deg);
      }
    }
    .item-content {
      max-height: 800px;
      padding-bottom: 10px;
    }
  }
`